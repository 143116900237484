import { createTheme } from "@mui/material/styles"

const theme = createTheme({
  navHeight: 74,
  sectionPadding: "padding-top: 1.5em; padding-bottom: 1.5em;",
  fonts: {
    primary: "Circular Std, sans-serif",
    secondary: "Halyard Display",
  },
  layout: {
    contentWidth: 1236,
  },
  palette: {
    primary: {
      // contrastText: "rgba(0, 0, 0, 0.87)",
      main: "#078855",
      light: "#F1F8F4",
      dark: "#004914",
    },
    secondary: {
      // contrastText: "rgba(0, 0, 0, 0.87)",
      main: "#151718",
      light: "#F7F8F9",
      dark: "#121414",
    },
    tertiary: {
      main: "#F1FBF5",
      light: "#F1F8F4",
      dark: "#FFCC6A",
    },
    text: {
      primary: "#151718",
      secondary: "#FFF",
      content: "#64748B",
      /*content: "#000000",*/
      tertiary: "#1A2933",
      disabled: "#10355A",
      hint: "#10355A",
      light: "#FFFFFF",
      titles: "#191D23",
      black: "#0D0F11",
    },
    alternate: {
      main: "rgb(247, 249, 250)",
      dark: "#e8eaf6",
    },
  },
  typography: {
    fontFamily: `"Circular Std", "DM Sans", "Open Sans", "Arial", sans-serif`,
  },
})

export default theme
